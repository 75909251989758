<template>
  <div class="wrap">
    <div class="nav">
      <div class="navList">
        <div @click="jump('1')">
          <span>首页</span>
          <span>Home</span>
        </div>
        <div @click="jump('2')">
          <span>服务案例</span>
          <span>Case</span>
        </div>
        <div @click="jump('3')" class="cur">
          <span>新闻资讯</span>
          <span>News</span>
        </div>
        <div @click="jump('4')">
          <span>业务伙伴</span>
          <span>Cooperation</span>
        </div>
        <div @click="jump('5')">
          <span>关于我们</span>
          <span>About</span>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="title">
        <p>办公室装饰的特点和技巧</p>
        <p>发布日期：2021-02-22</p>
      </div>
      <div class="text">
        <div>
          办公室装饰是一个比较烦琐的工程，尤其要注意细节。在设计上，可以充分利用办公室的所有空间进行办公。使得办公设计更具特色，从而使员工在工作中感受到温馨，让工作效率更大程度的提高，提升公司的形象。
        </div>
        <div>
          我们都知道，由于企业经营的产品不同，办公室装修的风格也不一样，但每一个企业都能通过不同的装饰给整个办公室增添生气，使整个办公室充满生机。比如绿色植物，是每一个企业都不可缺少的，它可以让人放松心情，保护环境。
        </div>
        <img src="../../public/imgs/news/text1.png" alt="" />
        <div>
          办公室整体色彩的搭配在办公室设计中占据主导地位，选用准确的色彩可以增添办公室的特色，给客户或办公人员一种最佳的感觉，因为人们在忙碌的工作中，并不会随心所欲地疲倦。
        </div>
        <div>
          很多办公室装修设计师还会根据办公室空间的大小来定做办公室家具，这也是出于多种考虑。订制的办公家具不仅能更好的称得上的办公室装修风格，而且也比一般的家具更耐用，可以说是“双赢”。
        </div>

        <div>
          打造一个特点的办公室装修设计，其细节与技巧所注意的有很多，就看办公室的运营者如何去看待了，一个好的设计不只能够增添雅观，更能为企业更加好处。
        </div>
        <img src="../../public/imgs/news/text2.png" alt="" />
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../components/Footer";
export default {
  name: "index",
  components: {
    Footer,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    jump(index) {
      if (index == 1) {
        this.$router.push({
          path: "/",
        });
      }
      if (index == 2) {
        this.$router.push({
          path: "/case",
        });
      }
      if (index == 3) {
        this.$router.push({
          path: "/news",
        });
      }
      if (index == 4) {
        this.$router.push({
          path: "/partner",
        });
      }
      if (index == 5) {
        this.$router.push({
          path: "/me",
        });
      }
    },
  },
};
</script>
<style scoped>
p {
  margin: 0;
  padding: 0;
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.wrap {
  height: 100vh;
  width: 1920px;
  margin: 0 auto;
}
.cur {
  color: #028ccd !important;
}
.nav .navList {
  position: absolute;
  top: 25px;
  right: 300px;
  height: 50px;
}
/* 导航 */
.nav {
  width: 100%;
  height: 100px;
  background: #fff url("../../public/imgs/indexNew/logo.png") no-repeat 76px
    center;
  overflow: hidden;
  cursor: pointer;
  position: fixed;
  top: 0;
  z-index: 8888;
}
.navList span {
  display: block;
}
.navList div {
  text-align: center;
  float: left;
  line-height: 50px;
  height: 70px;
  width: 70px;
  margin-right: 45px;
  font-size: 16px;
  color: #666666;
  position: relative;
  top: 0;
  transition: top 0.5s;
}
.navList div span:nth-of-type(1) {
  opacity: 1;
  transition: all 0.5s;
}
.navList div span:nth-of-type(2) {
  opacity: 0;
  transition: all 0.5s;
}
.navList div:hover {
  top: -50px;
}
.navList div:hover span:nth-of-type(1) {
  opacity: 0;
}
.navList div:hover span:nth-of-type(2) {
  opacity: 1;
}
.main {
  width: 1920px;
  margin: 100px auto 50px;
}
.title {
  background: #eee;
  height: 150px;
  overflow: hidden;
}
.title p {
  width: 1200px;
  margin: 0 auto;
  text-align: left;
}
.title p:nth-of-type(1) {
  font-size: 30px;
  color: #1f1f1f;
  margin-top: 46px;
}
.title p:nth-of-type(2) {
  line-height: 34px;
  font-size: 12px;
  color: #a0a0a0;
}

.text {
  width: 1200px;
  margin: 0 auto;
}
.text div {
  font-size: 14px;
  color: #5b5b5b;
  line-height: 24px;
  margin-top: 70px;
}
.text img {
  width: 100%;
}
</style>
